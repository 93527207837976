<!--
 * @Description: 出场收费-已收费 outCharged
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-04-08 14:32:31
 * @LastEditors: zhoucheng
-->
<template>
  <div class="container">
    <van-nav-bar title="停车记录"
                 left-text="返回"
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <van-row class="container-title">
      <van-col span="8"
               offset="8">
        <span style="color:#666666">已完成订单</span>
      </van-col>
      <van-col :span="8">
        <van-search v-model="value"
                    placeholder="搜索" />
      </van-col>
    </van-row>

    <div class="box">
      <van-list :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                class="container-info">
        <van-cell v-for="item in list"
                  :key="item"
                  style="padding:10px 0;margin-bottom:12px">
          <van-row class="info-title">
            <span class="info-name">{{item.name}}</span>
            <span :class="item.status == 0 ?'no-paid':'paid'">{{item.status == 0 ?'未完成':'已完成'}}</span>
          </van-row>
          <van-row class="info-content">
            <van-col :span="18">
              <div class="info-tips">车牌号
                <span class="info-tips-num">{{item.num}}</span>
              </div>
              <div class="info-tips">订单开始时间
                <span class="info-tips-time">{{item.startTime}}</span>
              </div>
              <div class="info-tips"
                   v-show="item.status == 1">订单结束时间
                <span style="color:black;padding-left:5px">{{item.endTime }}</span>
              </div>
            </van-col>
            <van-col :span="6">
              <div class="paid">
                <span class="paid-num">{{item.paid}}</span>
                <span class="paid-tip">元</span>
              </div>
            </van-col>
          </van-row>
        </van-cell>
      </van-list>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      value1: '已完成订单',
      show: false,
      option1: [
      ],
      list: [
        {
          id: '0',
          name: 'p1-001',
          num: '渝B36FDF',
          status: '1',
          startTime: '2021-04-06 10:30',
          endTime: '2021-04-06 14:30',
          paid: '18.00'
        },
        {
          id: '1',
          name: 'p1-002',
          num: '渝B36FDF',
          status: '1',
          startTime: '2021-04-06 10:30',
          endTime: '2021-04-06 14:30',
          paid: '20.00'
        }
      ],
      // 离场确认车牌号
      num: '渝H11557',
      // 图片路径
      src: require('@/assets/car.png'),
      loading: false,
      finished: false
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 弹出层打开
    toShow () {
      this.show = true
    },
    // 弹出层关闭
    onClose () {
      this.show = false
    }
  }
}
</script>
<style lang='less' scoped>
// @import url(); 引入公共css类
.container-title {
  height: 60px;
  background: white;
  display: flex;
  align-items: center;
}
.box {
  padding: 13px;
  .container-info {
    .van-cell {
      border-radius: 10px;
    }
    .info-content {
      padding: 10px;
      margin-top: 5px;
      display: flex;
      align-items: center;
      .paid {
        margin-top: 45px;

        .paid-num {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .paid-tip {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
      .info-tips {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #666666;
        // margin-bottom: 5px;
        .info-tips-num {
          color: black;
          padding-left: 50px;
        }
        .info-tips-time {
          color: black;
          padding-left: 5px;
        }
      }
    }
    .info-title {
      padding: 5px;
      border-bottom: 1px #e7e8e8 solid;
      text-align: center;
      .info-name {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .info-status {
        color: #82e77a;
        float: right;
        padding-right: 10px;
      }
    }
  }
}
/deep/.van-search__content {
  border-radius: 15px;
}
.van-button--plain.van-button--info {
  color: #1989fa;
  width: 78px;
  background: rgba(25, 169, 252, 0.06);
  border-radius: 5px;
}
/deep/.van-dropdown-menu__bar {
  box-shadow: none;
}
.pop {
  width: 340px;
  height: 404px;
  .show-title {
    height: 40px;
    background: #19a9fc;
    text-align: center;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
  }
  .show-num {
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .show-tip {
    text-align: center;
    margin: 5px 0;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
  }
  .image {
    padding: 5px;
    width: 310px;
    height: 210px;
  }
  .show-bottom {
    display: flex;
    justify-content: space-around;
    // position: relative;
    // bottom: -25px;
    margin-top: 22px;
  }
}
.no-paid {
  float: right;
  padding-right: 10px;
  color: #f56c6c;
}
.paid {
  float: right;
  padding-right: 10px;
  color: #909399;
}
</style>
